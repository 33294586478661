<template>
  <div class="min-vh-100 d-flex flex-row align-items-center login">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="5">

          <CCard class="p-4">
            <CCardBody>
              <CForm @submit.prevent="onLogin">

                <div class="imageContainerLogin">
                  <img src="/img/logo.png" />
                </div>

                <h4 class="mb-3">Login</h4>

                <input-email v-model="form.username" :vld="v$.form.username"/>
                <input-password v-model="form.password" :vld="v$.form.password" :create-mode="false"/>

                <CRow>
                  <CCol xs="12">
                    <input-submit class="loginInput" label="Login" :vld="v$" />
                  </CCol>
                  <CCol xs="12">
                    <CButton class="loginInput forgetPasswordButton" color="secondary" v-on:click="passwordForgotten()" type="button">Passwort vergessen?</CButton>
                  </CCol>
                </CRow>

              </CForm>
            </CCardBody>
          </CCard>

          <CCol class="footerLogin" :md="12">
            2023 © fidia Pharma GmbH | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
          </CCol>

          <cAlert color="danger" class="mt-3" v-if="hasError">
            <strong>Authentifizierung nicht erfolgreich!</strong>
            <br>
            <em>Antwort des Servers:</em>  {{error}}
          </cAlert>

        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import inputEmail from '@/components/form-elements/Email'
import inputPassword from '@/components/form-elements/Password'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'app-login',
  components: {
    inputEmail,
    inputPassword,
    inputSubmit
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      form: {
        username: {
          required, email
        },
        password: {
          required
        }
      }
    }
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    hasError () {
      return this.$store.getters['security/hasError']
    },
    error () {
      return this.$store.getters['security/error']
    }
  },
  methods: {
    onLogin () {
      const username = this.form.username
      const password = this.form.password
      this.$store.dispatch('security/login', { username, password }).then(() => {
        this.$router.push({ path: '/dashboard' })
      })
    },
    passwordForgotten (projektid) {
      this.$router.push({ path: '/security/password' })
    }
  }
}
</script>
